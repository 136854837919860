import {
	Field,
	Area,
	Popup,
	Picker,
	Search
} from 'vant';
import {
	getUserInfo,
	setUserInfo
} from '@/api/index.js';
import {areaList} from '@/utils/areaList.js';
import localStore from 'storejs';

export default {
	components: {
		[Field.name]: Field,
		[Area.name]: Area,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Search.name]: Search,
	},
	data() {
		return {
			isShowAreaList: false,
			selected: '',
			areaList: areaList,
		}
	},
	created() {
		this.getInfo();
	},
	methods: {
		// 根据县/区在省市区列表进行定位
		setSelected(countyName) {
			let arr = Object.entries(areaList.county_list);
			for (let i = 0, len = arr.length; i < len; i++) {
				if(arr[i][1] === countyName) {
					this.selected = arr[i][0];
					break;
				}
			}
		},
		// 根据国家名称在国家地区列表进行定位
		setDefaultIdx(provinceName) {
			this.defaultIdx = this.columns.findIndex( e => e == provinceName);
		},
		getInfo() {
			getUserInfo().then(res => {
				// this.data.provinceName,
				// area: "河北省邢台市巨鹿县"
				// cityName: "邢台市"
				// clientTypeName: "普通客人(海外)"
				// company: "河北三昌纺织有限公司"
				// companyAdd: "中國河北巨鹿縣工業園區"
				// countyName: "巨鹿县"
				// department: "部门"
				// isOldCustomer: false
				// mailbox: ""
				// name: "夏伟哲"
				// nickName: "luobin"
				// phone: "15112282248"
				// position: "业务经理"
				// provinceName: "河北省"
				// trade: "其他设计师"
				this.data = res.data;
				if (res.data) {
					if(this.lang == 'en') {
						this.setDefaultIdx(res.data.provinceName)
					} else {
						this.setSelected(res.data.countyName)
					}
				}
			})
		},
		// 进入拍名片页面
		shootCard(lang = '') {
			let path = lang === 'en' ? '/shootCard_en' : '/shootCard';
			this.$router.push(path)
		},
		// 校验必填项
		validateInput(lang) {

			for (let e of this.style.informationOptions) {
				
				if (lang === 'en') {
					// 校验必填项是否有内容
					if (e.isShow && e.isRequired && e.id !== 'area' && !this.data[e.id]) {
						this.$toast(`${e.name} is required`);
						// this.$refs[e.id][0].focus();
						return false
					}
					// 校验国家必填项是否有内容
					if (e.isShow && e.isRequired && e.id === 'area' && !this.data.provinceName) {
						this.$toast('Country is required');
						// this.$refs[e.id][0].focus();
						return false
					}
					// 校验城市必填项是否有内容
					if (e.isShow && e.isRequired && e.id === 'area' && !this.data.cityName) {
						this.$toast('City is required');
						// this.$refs[e.id][0].focus();
						return false
					}
					
				} else {
					// 校验必填项是否有内容
					if (e.isShow && e.isRequired &&  !this.data[e.id]) {
						this.$toast(`${e.title}不能为空`);
						// this.$refs[e.id][0].focus();
						return false
					}
				}
				
				
				// 邮箱为必填项时，校验邮箱格式
				if (e.isShow && e.isRequired && e.id === 'mailbox' && !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.data.mailbox)) {
					this.$toast(lang === 'en' ? 'Wrong format of E-mail' : '邮箱格式错误');
					// this.$refs.mailbox.focus();
					return false;
				}
			}
			
			// 最少字符限制：公司2、部门2、职位2、公司地址5; 英文版最少字符数翻倍
			if (lang === 'en') {
				if (this.data.company && this.data.company.length < 2) {
					this.$toast('Company: Please enter at least 2 characters');
					return false;
				}
				if (this.data.department && this.data.department.length < 2) {
					this.$toast('Department: Please enter at least 2 characters');
					return false;
				}
				if (this.data.position && this.data.position.length < 2) {
					this.$toast('Position: Please enter at least 2 characters');
					return false;
				}
				if (this.data.companyAdd && this.data.companyAdd.length < 5) {
					this.$toast('Company Address: Please enter at least 5 characters');
					return false;
				}
			} else {
				if (this.data.company && this.data.company.length < 2) {
					this.$toast('公司名最少2个字');
					return false;
				}
				if (this.data.department && this.data.department.length < 2) {
					this.$toast('部门最少2个字');
					return false;
				}
				if (this.data.position && this.data.position.length < 2) {
					this.$toast('职位最少2个字');
					return false;
				}
				if (this.data.companyAdd && this.data.companyAdd.length < 5) {
					this.$toast('公司地址最少5个字');
					return false;
				}
			}

			return true;
		},
		nextStep(lang = '') {
			// 校验所有填写项是否合格
			if (!this.validateInput(lang)) return false;

			this.$toast.loading({
				loadingType: 'spinner',
				forbidClick: true,
				duration: 0,
			});

			let params = {
				unionid: this.unionid,
				name: this.data.name || '',
				position: this.data.position || '',
				company: this.data.company || '',
				area: this.data.area || '',
				companyAdd: this.data.companyAdd || '',
				department: this.data.department || '',
				email: this.data.mailbox || '',
				provinceName: this.data.provinceName, // 中文-省份; 英文-国家
				cityName: this.data.cityName,
			}
			this.lang == 'zh' && (params.countyName = this.data.countyName)
			
			setUserInfo(params).then(res => {
				this.setPageData(this.path, this.data); // 更新该页的回填数据
				this.pageSwitch(res.data)
			}).catch(err => {
				err ? this.$toast(err.message) : this.$toast.clear();
			});
		}
	}
}
