<template>
	<div class="page">
		<div class="container">
			<div class="notes">
				<h1>{{data.isOldCustomer ? 'Please confirm' : ''}} Personal Information</h1>
				<h4>* required fields</h4>
			</div>
			<div class="content">
				<div class="form">

					<template v-for="(item, idx) in style.informationOptions">
						<div v-if="item.isShow" :key="idx">
							
							<div v-if="item.id === 'area'">
								<span>Country</span>
								<span v-if="item.isRequired">&nbsp;*</span>
								<!-- <van-field readonly clickable :value="data.provinceName" right-icon="play"  @click="showPicker" /> -->
								<van-field readonly clickable :value="data.provinceName" right-icon="play"  @click="showPicker">
									<template #input>
										<input v-model.trim="data.provinceName" type="text" :ref="item.id" class="input" :style="{color: style.btnColor}" placeholder="">
									</template>
								</van-field>
								
								<van-popup v-model="isShowPicker" round position="bottom">
									<van-picker show-toolbar :columns="columns" :default-index="defaultIdx" confirm-button-text="Confirm" cancel-button-text="Cancel" @cancel="isShowPicker = false" @confirm="onConfirm" >
										<template #title>
											<van-search v-model="keyword" shape="round" background="#ffffff" placeholder="Search" @input="searchLocation" />
										</template>
									</van-picker>
								</van-popup>
								
								<span>City</span>
								<span v-if="item.isRequired">&nbsp;*</span>
								<van-field>
									<template #input>
										<input v-model.trim="data.cityName" type="text" :ref="item.id" class="input" :style="{color: style.btnColor}" placeholder="">
									</template>
								</van-field>
							</div>
							<div v-else-if="item.id === 'position'">
								<span>{{item.name}}</span>
								<span v-if="item.isRequired">&nbsp;*</span>
								<van-field>
									<template #input>
										<input v-model.trim="data[`${item.id}`]" type="text" :ref="item.id" class="input" :style="{color: style.btnColor}" placeholder="Job title or position">
									</template>
								</van-field>
							</div>
							<div v-else>
								<span>{{item.name}}</span>
								<span v-if="item.isRequired">&nbsp;*</span>
								<van-field>
									<template #input>
										<input v-model.trim="data[`${item.id}`]" type="text" :ref="item.id" class="input" :style="{color: style.btnColor}" placeholder="">
									</template>
								</van-field>
							</div>
							
						</div>
					</template>
					

				</div>
				
				<div>
					<!-- 老客户显示 “重新上传名片”-->
					<van-button v-if="data.isOldCustomer && style.isAllowSwitch" type="primary" block :color="style.btnColor" class="next-btn radius" @click="shootCard('en')">
						Re-upload Business Card
					</van-button>

					<van-button type="primary" block :color="style.btnColor" class="next-btn radius" @click="nextStep('en')">Next</van-button>
				</div>

			</div>
		</div>
		<div class="footer">
			<bottom :mode="style.mode" :level="style.level" language="en"></bottom>
		</div>
	</div>
</template>

<script>
	
	import { getCountryList } from '@/api/index.js';

	import commonMixin from '@/view/process/mixin/common.js';
	import informationMixin from '@/view/process/mixin/information.js';
	import localStore from 'storejs';

	export default {
		mixins: [commonMixin, informationMixin],
		data() {
			return {
				keyword: '',
				isShowPicker: false,
				locationOptions: [],
				defaultIdx: 1,
				columns: [],
			}
		},
		created() {
			this.setLanguge('en'); // 设置语言环境
			this.getCountryList();
		},
		methods: {
			// 显示国家列表
			showPicker() {
				this.isShowPicker = true;
				this.columns = this.locationOptions;
				this.setDefaultIdx(this.data.provinceName)
			},
			// 国家列表确认选择
			onConfirm(value) {
				this.data.provinceName = value;
				this.isShowPicker = false;
				this.keyword = '';
			},
			// 获取国家列表
			getCountryList() {
				let countryList = localStore('countryList'); // 从本地存储取国家列表
				if (countryList) {
					this.handleData(countryList)
				} else {
					getCountryList().then(res => {
						if(res.code == 200) {
							localStore.set('countryList', res.data || []); // 本地存储取国家列表
							this.handleData(res.data)
						}
						
					}).catch(err => {
						console.log(err);
					});
				}
			},
			// 国家列表处理
			handleData(arr = []) {
				let columns = arr.map( e => e.area_name);
				this.columns = columns;
				this.locationOptions = Object.freeze(columns);
			},
			// 搜索符合条件的国家列表
			searchLocation() {
				this.columns =  this.keyword ? this.locationOptions.filter( e => e.toLowerCase().includes(this.keyword.toLowerCase())) : this.locationOptions;
			},
		},
	}
</script>

<style lang="less" scoped>
	// @color: #9F9D9D;
	@color: #6D6D6D;
	
	.notes {
		margin: 6vh auto 4vh;

		h1,
		h4 {
			text-align: center;
			color: @color;
			font-family: 'DIN-Regular';
		}

		h1 {
			font-size: 20px;
			margin-bottom: 2vh;
		}

		h4 {
			font-size: 14px;
		}
	}

	.content {
		margin-bottom: 4vh;
		min-height: 50vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.form {
			margin: 0 50px;
			margin-bottom: 5vh;
			font-size: 14px;
			font-family: 'DIN-Regular';

			.input {
				width: 100%;
				border: none;

				&::placeholder {
					color: #bbb;
				}
			}

			/deep/ .van-cell {
				margin: 1vh auto 2vh;
				padding: 1vh 10px;
				border-radius: 5px;
				
				.van-icon-play {
					transform: rotate(90deg);
				}
			}
		}
		
		/deep/ .van-picker {
			padding-top: 10px;
			.van-cell {
				margin: 1vh auto 2vh;
				padding: 1vh 10px;
				border-radius: 5px;
			}
			 .van-picker__toolbar{
				.van-cell {
					margin: 0;
					padding-left: 0;
				}
				.van-search {
					width: 240px;
					padding-left: 0;
					padding-right: 0;
					
					.van-field__control {
						text-align: center;
						
						&::placeholder {
							text-align: center;
						}
					}
				}
			}
		}
		
		.next-btn {
			width: 260px;
			margin: 0 auto 3vh;
			letter-spacing: 1px;
			font-size: 18px;
		}
	}
</style>
